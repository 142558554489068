import React, { PureComponent, useState, useEffect, useRef } from 'react'
import Highlighter from 'react-highlight-words'
import {
    Table,
    Tooltip,

} from 'antd'
import _ from 'lodash'
import { NonActiveMcp } from '../action/dashboardAction'
import moment, { relativeTimeRounding } from 'moment'
import { connect, useSelector, useDispatch } from 'react-redux'
import styles from './styles.less'
import { timeStampFormat, socketUrl, dateFormat, apiUrl } from '../../../settings'

function OfflineMcp(props) {
    const dispatch = useDispatch()

    const { currentUser } = props;
    const { offlineMcps } = useSelector((state) => ({
        offlineMcps: state.dashboard.offlineMcps
    })
    )
    useEffect(() => {
        dispatch(NonActiveMcp(currentUser.companyId))
    }, [])
    const OfflineMcp = [
        {
            title: 'MCP Name',
            dataIndex: 'name',
            key: 'name',
            searchTextName: true
        },
        {
            title: 'In Used',
            dataIndex: 'used',
            key: 'used',
            render: (value) => {
                return (
                    <React.Fragment>
                        {value ? <label className={styles.labelSuccess}>Yes</label> :
                            <label className={styles.labelInfo}>No</label>}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Last Entry',
            dataIndex: 'lastEntry',
            key: 'lastEntry',
            render: (date) => {
                return (
                    date == 0 ? '-' : <Tooltip title={moment(date).format(timeStampFormat)}>
                        {date && moment(date).fromNow()}
                    </Tooltip>
                )
            }
        }
    ]
    return (
        <div className={styles.tableWrapper}>
            <div className={styles.invertHeading}>Offline MCP's</div>
            {
                offlineMcps && <Table
                bordered
                size="small"
                columns={OfflineMcp}
                dataSource={offlineMcps.data}
                pagination={false}
                scroll={{ y: 70 }}
            />
            }
            
        </div>
    )
}
export default OfflineMcp