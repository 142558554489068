import React from 'react'
import { push } from 'connected-react-router'
import _ from 'lodash'
import Path from 'path-parser'
import { lazy } from 'react'
import { DashboardOutlined } from '@ant-design/icons'
/*

const Dashboard = lazy(() => import('./containers/dashboard'))
const Undercons = lazy(() => import('./containers/undercons'))
const DefaultSettings = lazy(() => import('./containers/defaultSettings/setting'))
const AddFarmer = lazy(() => import('./containers/farmer/add'))
const AllFarmers = lazy(() => import('./containers/farmer/all'))
const FarmerSettings = lazy(() => import('./containers/farmer/setting/add'))
const AllFarmerSettings = lazy(() => import('./containers/farmer/setting/all'))
const AddMcc = lazy(() => import('./containers/mcp/add'))
const AllMcc = lazy(() => import('./containers/mcp/all'))
const AddMcpGroup = lazy(() => import('./containers/mcpGroup/add'))
const AllMcpGroup = lazy(() => import('./containers/mcpGroup/all'))
const AddFarmerCategory = lazy(() => import('./containers/farmerCategories/add'))
const AllFarmerCategory = lazy(() => import('./containers/farmerCategories/all'))
const Collections = lazy(() => import('./containers/collections'))
const Payments = lazy(() => import('./containers/payment'))
const Transactions = lazy(() => import('./containers/transactions'))
const AddTicket = lazy(() => import('./containers/tickets/add'))
const AllTicket = lazy(() => import('./containers/tickets/all'))
const TicketDetails = lazy(() => import('./containers/tickets/details'))
const AddRateChart = lazy(() => import('./containers/rateChart/add'))
const DateWiseMilkPurchaseSummary = lazy(() => import('./containers/reports/dateWiseMilkPurchaseSummary'))
const FarmerLedgerReport = lazy(() => import('./containers/reports/farmerLedgerReport'))
const McpSampleReport = lazy(() => import('./containers/reports/mcpSampleReport'))
const MilkBillReport = lazy(() => import('./containers/reports/milkBillReport'))
const MilkEditReport = lazy(() => import('./containers/reports/milkEditReport'))
const editRateChart = lazy(() => import('./containers/rateChart/edit/index'))
const AllRateCharts = lazy(() => import('./containers/rateChart/all/index'))
*/
//import Testing from './containers/aa/views/test'

import Dashboard from './elements/dashboard/views/live'
// const Dashboard = lazy(() => import('./elements/dashboardNew/views'))

const Undercons = lazy(() => import('./containers/undercons'))
const DefaultSettings = lazy(() => import('./containers/defaultSettings/setting/add'))
const AllSettings = lazy(() => import('./containers/defaultSettings/setting/all'))
const AddFarmer = lazy(() => import('./containers/farmer/add'))
const AllFarmers = lazy(() => import('./containers/farmer/all'))
const UnbilledCollections = lazy(() => import('./containers/farmer/unbilledCollections'))
const FarmerSettings = lazy(() => import('./containers/farmer/setting/add'))
const AllFarmerSettings = lazy(() => import('./containers/farmer/setting/all'))
const AddMcc = lazy(() => import('./containers/mcp/add'))
const AllMcc = lazy(() => import('./containers/mcp/all'))
const IncentiveSettings = lazy(() => import('./containers/mcp/incentiveSettings/addIncentive'))
const IncentiveSettingsList = lazy(() => import('./containers/mcp/incentiveSettings/all'))
const CollectionQuantityIncentiveSettings = lazy(
  () => import('./containers/mcp/incentiveSettings/collectionQuantityIncentive'))
const BonusSettings = lazy(() => import('./containers/mcp/incentiveSettings/bonusSettings'))
const AddMcpGroup = lazy(() => import('./containers/mcpGroup/add'))
const AllMcpGroup = lazy(() => import('./containers/mcpGroup/all'))
const AddFarmerCategory = lazy(() => import('./containers/farmerCategories/add'))
const Collections = lazy(() => import('./containers/collections'))
const AllPayments = lazy(() => import('./containers/payment/all'))
const AddPayment = lazy(() => import('./containers/payment/add'))
const Transactions = lazy(() => import('./containers/transactions'))
const AddTicket = lazy(() => import('./containers/tickets/add'))
const AllTicket = lazy(() => import('./containers/tickets/all'))
const TicketDetails = lazy(() => import('./containers/tickets/details'))
const AddRateChart = lazy(() => import('./containers/rateChart/add'))
const AddStandardRateChart = lazy(() => import('./containers/rateChart/standard'))
const DateWiseMilkPurchaseSummary = lazy(() => import('./containers/reports/dateWiseMilkPurchaseSummary'))
const McpwiseMilkPurchaseSummary = lazy(() => import('./containers/reports/mcpWiseMilkPurchaseSummary'))
const FarmerLedgerReport = lazy(() => import('./containers/reports/farmerLedgerReport'))
const AddTemplate = lazy(() => import('./containers/smsTemplate/add'))
const AllTemplates = lazy(() => import('./containers/smsTemplate/all'))
const McpMilkBillReport = lazy(() => import('./containers/reports/mcpMilkBillReport'))
const MilkEditReport = lazy(() => import('./containers/reports/milkEditReport'))
const editRateChart = lazy(() => import('./containers/rateChart/edit/index'))
const AllRateCharts = lazy(() => import('./containers/rateChart/all/index'))
const MilkDispatch = lazy(() => import('./containers/milkDispatch/index'))
const AddBulkCollection = lazy(() => import('./containers/bulkCollection/add'))
const AddBulkMeasurement = lazy(() => import('./containers/bulkCollection/scale'))
const AllBulkMeasurement = lazy(() => import('./containers/bulkCollection/all'))
const Compare = lazy(() => import('./containers/bulkCollection/comparision'))
const AddManager = lazy(() => import('./containers/Manager/add'))
const AllManager = lazy(() => import('./containers/Manager/all'))
const BillSetting = lazy(() => import('./containers/bill/setting'))
const AllBills = lazy(() => import('./containers/bill/all'))
const FarmerBillDetail = lazy(() => import('./containers/bill/details'))
const AllSms = lazy(() => import('./containers/sms/all'))
const AddBulkPurchase = lazy(() => import('./containers/bulkPurchase/add'))
const AllBulkPurchase = lazy(() => import('./containers/bulkPurchase/all'))
const AddProducts = lazy(() => import('./containers/products/add'))
const AllProducts = lazy(() => import('./containers/products/all'))
const AllSales = lazy(() => import('./containers/sales'))
const AllAnnouncement = lazy(() => import('./elements/announcement/views/all'))
const AddAnnouncement = lazy(() => import('./elements/announcement/views/add'))
const AllConflicts = lazy(() => import('./elements/conflicts/views/all'))
const SmsPurchase = lazy(() => import('./elements/smsPurchase/views/all'))


//const Testing =import('./containers/aa/views/test')
const menu = [
  // {
  //   'path': '/Testing',
  //   'name': 'Testing',
  //   'icon': 'Testing',
  //   'title': 'Testing',
  //   'key': 'Testing',
  //   'component': Testing,
  //   'authority': [
  //     'company',
  //     'manager'
  //   ]
  // },

  {
    'path': '/dashboard',
    'name': 'Dashboard',
    'icon': 'dashboard',
    'title': 'Dashboard',
    'key': 'dashboard',
    'homepage': true,
    'component': Dashboard,
    'authority': [
      'company',
      'manager'
    ]
  },
  {
    'path': '/defaultSetting',
    'name': 'Settings',
    'icon': 'setting',
    'key': 'setting',
    'authority': [
      'company'
    ],
    'children': [
      {
        'path': '/defaultSetting/add',
        'name': 'Default Settings',
        'key': 'defaultSettings',
        'title': 'Default Settings',
        'component': DefaultSettings
      },
      {
        'path': '/mcp/incentiveSettings',
        'name': 'Quality Incentive Settings',
        'title': 'Add Incentive Settings',
        'key': 'incentiveSettings',
        'component': IncentiveSettings
      },
      {
        'path': '/mcp/collectionQuantityIncentiveSettings',
        'name': 'Quantity Incentive Settings',
        'title': 'All Incentive Settings',
        'key': 'CollectionQuantityIncentiveSettings',
        'component': CollectionQuantityIncentiveSettings
      },
      {
        'path': '/mcp/bonusSettings',
        'name': 'Bonus Settings',
        'title': 'Bonus Settings',
        'key': 'BonusSettings',
        'component': BonusSettings
      },
      // {
      //   'path': '/defaultSetting/all',
      //   'name': 'All Settings',
      //   'key': 'all',
      //   'title': 'All Settings',
      //   'component': AllSettings
      // },
    ]
  },
  {
    'path': '/mcpGroup',
    'name': 'Manager/MCP Group',
    'key': 'mcpGroup',
    'icon': 'usergroup-add',
    'authority': [
      'company'
    ],
    'children': [
      {
        'path': '/mcpGroup/add',
        'name': 'Add MCP Group',
        'key': 'addMcpGroup',
        'title': 'Add MCP Group',
        'component': AddMcpGroup
      },
      {
        'path': '/mcpGroup/all',
        'name': 'All MCP Group',
        'title': 'All MCP Group',
        'component': AllMcpGroup
      },
      {
        'path': '/mcpGroup/editMcpGroup/:id',
        'name': 'Update MCP Group',
        'title': 'Update MCP Group',
        'key': 'editMcpGroup',
        dontShowOnMenu: true,
        'component': AddMcpGroup
      },
      {
        'path': '/manager/add',
        'name': 'Add Manager',
        'key': 'addManager',
        'component': AddManager,
        'authority': [
          'company'
        ]
      },
      {
        'path': '/manager/edit/:id',
        'name': 'Edit Manager',
        'key': 'editManager',
        'dontShowOnMenu': true,
        'component': AddManager,
        'authority': [
          'company',
          'manager'
        ]
      },
      {
        'path': '/manager/all',
        'name': 'All Manager',
        'key': 'allManager',
        'component': AllManager,
        'authority': [
          'company'
        ]
      }
    ]
  },
  {
    'path': '/mcp',
    'name': 'MCP',
    'key': 'mcp',
    'icon': 'shop',
    'authority': [
      'company',
      'manager'
    ],
    'children': [
      {
        'path': '/mcp/add',
        'name': 'Add MCP',
        'key': 'addMcp',
        'title': 'Add MCP',
        'component': AddMcc
      },
      {
        'path': '/mcp/all',
        'name': 'All MCP',
        'title': 'All MCP',
        'component': AllMcc
      },
      {
        'path': '/mcp/editMcc/:id',
        'name': 'Update MCP',
        'title': 'Update MCP',
        'key': 'editMcc',
        dontShowOnMenu: true,
        'component': AddMcc
      }
    ]
  },
  {
    'path': '/additionalSetting',
    'name': 'Incentive/Bonus Settings',
    'key': 'additionalSetting',
    'icon': 'setting',
    'authority': [
      'company',
      'manager'
    ],
    'children': [
      // {
      //   'path': '/mcp/incentiveSettings',
      //   'name': 'Quality Incentive Settings',
      //   'title': 'Add Incentive Settings',
      //   'key': 'incentiveSettings',
      //   'component': IncentiveSettings
      // },
      {
        'path': '/mcp/incentiveSettingsList',
        'name': 'Quality Incentive List',
        'title': 'All Incentive Settings',
        'key': 'incentiveSettings',
        'component': IncentiveSettingsList
      },
      {
        'path': '/mcp/collectionQuantityIncentiveSettings',
        'name': 'Quantity Incentive Settings',
        'title': 'All Incentive Settings',
        'key': 'CollectionQuantityIncentiveSettings',
        'component': CollectionQuantityIncentiveSettings
      },
      // {
      //   'path': '/mcp/bonusSettings',
      //   'name': 'Bonus Settings',
      //   'title': 'Bonus Settings',
      //   'key': 'BonusSettings',
      //   'component': BonusSettings
      // },
      {
        'path': '/mcp/incentiveSettings/:id',
        'name': 'Edit Incentive Settings',
        'title': 'Edit Incentive Settings',
        'key': 'editIncentiveSettings',
        dontShowOnMenu: true,
        'component': IncentiveSettings
      }
    ]
  },
  {
    'path': '/farmer',
    'name': 'Farmers',
    'key': 'farmer',
    'icon': 'team',
    'authority': [
      'company',
      'manager'
    ],
    'children': [
      /* {
        'path': '/farmer/farmerCategory/add',
        'name': 'Add Farmer Category',
        'key': 'addFarmerCategory',
        'title': 'Add Farmer Category',
        'component': AddFarmerCategory
      },
      {
        'path': '/farmer/farmerCategory/all',
        'name': 'All Farmer Category',
        'title': 'All Farmer Category',
        'component': AllFarmerCategory
      }, */
      {
        'path': '/farmer/editFarmerCategory/:id',
        'name': 'Update Farmer Category',
        'title': 'Update Farmer Category',
        'key': 'editFarmerCategory',
        dontShowOnMenu: true,
        'component': AddFarmerCategory
      },
      {
        'path': '/farmer/settings/add',
        'name': 'Add Farmer Settings',
        'title': 'Farmer Settings',
        'key': 'farmerSettings',
        // dontShowOnMenu: true,
        'component': FarmerSettings
      },
      {
        'path': '/farmer/settings/edit/:id',
        'name': 'Farmer Settings',
        'title': 'Farmer Settings',
        'key': 'farmerSettingsEdit',
        dontShowOnMenu: true,
        'component': FarmerSettings
      },
      {
        'path': '/farmer/settings/all',
        'name': 'All Farmer Settings',
        'title': 'All Farmer Settings',
        'key': 'allFarmerSettings',
        // dontShowOnMenu: true,
        'component': AllFarmerSettings
      },
      {
        'path': '/farmer/add',
        'name': 'Add Farmer',
        'title': 'Add Farmer',
        'key': 'addFarmer',
        'component': AddFarmer
      },
      {
        'path': '/farmer/all',
        'name': 'All Farmers',
        'title': 'All Farmers',
        'component': AllFarmers
      },
      {
        'path': '/farmer/edit/:id',
        'name': 'Update Farmer',
        'title': 'Update Farmer',
        'key': 'editFarmer',
        dontShowOnMenu: true,
        'component': AddFarmer
      },
      {
        'path': '/farmer/collections/:farmerUUID/:mcpId',
        'name': 'Unbilled Collections Farmer',
        'title': 'Unbilled Collections Farmer',
        'key': 'unbilledcollectionsFarmer',
        dontShowOnMenu: true,
        'component': UnbilledCollections
      },
      {
        'path': '/farmer/merge/:mergeId',
        'name': 'Merge Farmer',
        'title': 'Merge Farmer',
        'key': 'mergeFarmer',
        dontShowOnMenu: true,
        'component': AddFarmer
      }

    ]
  },
  {
    'path': '/collections',
    'name': 'Collections',
    'icon': 'database',
    'key': 'collections',
    'component': Collections,
    'authority': [
      'company',
      'manager'
    ]
  },
  {
    'path': '/rateChart',
    'name': 'Rate Chart',
    'icon': 'line-chart',
    'key': 'rateChart',
    'authority': [
      'company',
      'manager'
    ],
    'children': [
      {
        'path': '/addRateCharts',
        'name': 'Add Rate Chart',
        'key': 'addRateChart',
        'component': AddRateChart,
        'authority': [
          'company',
          'manager'
        ]
      },

      {
        'path': '/allRateCharts',
        'name': 'All Rate Charts',
        'key': 'allRateCharts',
        'component': AllRateCharts,
        'authority': [
          'company',
          'manager'
        ]
      },
      {
        'path': '/rateCharts/edit/:id',
        'name': 'Rate Charts Edit',
        'title': 'Rate Charts Edit',
        'key': 'edit',
        'component': editRateChart,
        dontShowOnMenu: true

      }
    ]
  },
  {
    'path': '/ticket',
    'name': 'Tickets',
    'key': 'ticket',
    'icon': 'audit',
    dontShowOnMenu: true,
    'authority': [
      'company'
    ],
    'children': [
      {
        'path': '/ticket/add',
        'name': 'Add Ticket',
        'title': 'Add Ticket',
        'component': AddTicket
      },
      {
        'path': '/ticket/all',
        'name': 'All Ticket',
        'title': 'All Ticket',
        'component': AllTicket
      },
      {
        'path': '/ticketDetails/:id',
        'name': 'Ticket',
        'icon': 'dashboard',
        'key': 'ticketDetails',
        'dontShowOnMenu': true,
        'component': TicketDetails
      }
    ]
  },
  // {
  //   'path': '/sales',
  //   'name': 'Sales',
  //   'icon': 'book',
  //   'key': 'sales',
  //   'component': AllSales
  // },
  {
    'path': '/product',
    'name': 'Product',
    'key': 'product',
    'icon': 'book',
    'children': [
      {
        'path': '/product/add',
        'name': 'Add Product',
        'key': 'addProduct',
        'component': AddProducts,
        'authority': [
          'company',
          'manager'
        ]
      },
      {
        'path': '/product/edit/:id',
        'name': 'Edit Product',
        'key': 'editProduct',
        'component': AddProducts,
        dontShowOnMenu: true,
        'authority': [
          'company',
          'manager'
        ]
      },
      {
        'path': '/product/all',
        'name': 'All Products',
        'key': 'allProducts',
        'component': AllProducts,
        'authority': [
          'company',
          'manager'
        ]
      }
    ],
    'authority': [
      'company',
      'manager'
    ]
  },
  {
    'path': '/payments',
    'name': 'Payments',
    'icon': 'dollar',
    'key': 'payments',
    'children': [
      {
        'path': '/payments/add',
        'name': 'Add Payment',
        'icon': 'dollar',
        'key': 'addPayments',
        'component': AddPayment,
        'authority': [
          'company',
          'manager'
        ]
      },
      {
        'path': '/payments/all',
        'name': 'All Payments',
        'icon': 'dollar',
        'key': 'allPayments',
        'component': AllPayments,
        'authority': [
          'company',
          'manager'
        ]
      }

    ],
    'authority': [
      'company',
      'manager'
    ]
  },
  // {
  //   'path': '/transactions',
  //   'name': 'Transactions',
  //   'icon': 'dollar',
  //   'key': 'transactions',
  //   'component': Transactions,
  //   'authority': [
  //     'company',
  //     'manager'
  //   ]
  // },
  {
    'path': '/report',
    'name': 'Reports',
    'key': 'reports',
    'icon': 'usergroup-add',
    'authority': [
      'company',
      'manager'
    ],
    'children': [
      {
        'path': '/report/dateWiseMilkPurchaseSummary',
        'name': 'Date Wise Milk Purchase',
        'title': 'Date Wise Milk Purchase',
        'key': 'dateWiseMilkPurchase',
        'component': DateWiseMilkPurchaseSummary
      },
      {
        'path': '/report/mcpWiseMilkPurchaseSummary',
        'name': 'MCP Wise Milk Purchase',
        'title': 'MCP Wise Milk Purchase',
        'key': 'mcpWiseMilkPurchase',
        'component': McpwiseMilkPurchaseSummary
      },

      {
        'path': '/report/milkBillReport',
        'name': 'Milk Purchase Report',
        'title': 'Milk Purchase Report',
        'key': 'milkBillReport',
        'component': McpMilkBillReport
      },
      {
        'path': '/report/farmerLedgerReport',
        'name': 'Farmer Ledger Report',
        'title': 'Farmer Ledger Report',
        'key': 'farmerLedgerReport',
        'component': FarmerLedgerReport
      },
      {
        'path': '/report/milkEditReport',
        'name': 'Milk Edit Report',
        'title': 'Milk Edit Report',
        'key': 'milkEditReport',
        'component': MilkEditReport
      },
      {
        'path': '/sales',
        'name': 'Sales',
        'key': 'sales',
        'component': AllSales
      },
      {
        'path': '/transactions',
        'name': 'Transactions',
        'icon': 'dollar',
        'key': 'transactions',
        'component': Transactions,
        'authority': [
          'company',
          'manager'
        ]
      },
    ]
  },
  // {
  //   'path': '/milkDispatch',
  //   'name': 'Milk Dispatch',
  //   'icon': 'database',
  //   'key': 'milkDispatch',
  //   'component': MilkDispatch,
  //   'authority': [
  //     'company',
  //     'manager'
  //   ]
  // },
  {
    'path': '/rmrd-report',
    'name': 'RMRD Report',
    'icon': 'line-chart',
    'key': 'rmrd',
    dontShowOnMenu: true,
    'authority': [
      'company'
    ],
    'children': [
      {
        'path': '/rmrd/addMilkReception',
        'name': 'Add Milk Reception',
        'key': 'addMilkReception',
        'component': AddBulkCollection,
        'authority': [
          'company'
        ]
      },
      {
        'path': '/editMilkReception/:id',
        'name': 'Edit Milk Reception',
        'key': 'editMilkReception',
        'component': AddBulkCollection,
        dontShowOnMenu: true,
        'authority': [
          'company'
        ]
      },
      // {
      //   'path': '/allMilkReception',
      //   'name': 'All Milk Reception',
      //   'key': 'allMilkReception',
      //   'component': AllBulkMeasurement,
      //   'authority': [
      //     'company'
      //   ]
      // }
    ]
  },
  {
    'path': '/template',
    'name': 'Template',
    'icon': 'file',
    'key': 'template',
    'children': [
      {
        'path': '/template/add',
        'name': 'Add Template',
        'icon': 'sheet',
        'key': 'addTemplate',
        'component': AddTemplate,
        'authority': [
          'company'
        ]
      },
      {
        'path': '/template/edit/:id',
        'name': 'Edit Template',
        'key': 'editTemplate',
        'component': AddTemplate,
        dontShowOnMenu: true,
        'authority': [
          'company'
        ]
      },
      {
        'path': '/template/all',
        'name': 'All Templates',
        'key': 'allTemplates',
        'component': AllTemplates,
        'authority': [
          'company'
        ]
      }

    ]
  },
  {
    'path': '/sms',
    'name': 'Sms',
    'icon': 'message',
    'key': 'sms',
    'children': [
      {
        'path': '/sms/all',
        'name': 'All Sms',
        'key': 'allSms',
        'component': AllSms,
        'authority': [
          'company'
        ]
      },
      {
        'path': '/smsPurchase',
        'name': 'SMS Purchase',
        'icon': 'dollar',
        'key': 'smsPurchase',
        'component': SmsPurchase
      },
    ]
  },
  {
    'path': '/billing',
    'name': 'Billing',
    'icon': 'file-text',
    'key': 'bill',
    'authority': [
      'company'
    ],
    'children': [
      {
        'path': '/billing/setting',
        'name': 'Billing Setting',
        'key': 'billingSetting',
        'component': BillSetting,
        'authority': [
          'company'
        ]
      },
      {
        'path': '/billing/List',
        'name': 'All Bills',
        'key': 'allBills',
        'component': AllBills,
        'authority': [
          'company'
        ]
      },
      {
        'path': '/billing/list/Detail',
        'name': 'Farmer Bill Details',
        'key': 'farmerBillDetail',
        dontShowOnMenu: true,
        'component': FarmerBillDetail,
        'authority': [
          'company'
        ]
      }
    ]
  },
  {
    'path': '/bulkMilkPurchase',
    'name': 'Bulk Milk Purchase',
    'icon': 'file',
    'key': 'bulkPurchase',
    dontShowOnMenu: true,
    // 'component': AddBulkPurchase,
    'authority': [
      'company',
      'manager'
    ],
    'children': [
      {
        'path': '/bulkMilkPurchase/add',
        'name': 'Add Bulk Purchase',
        'key': 'addBulkMilkPurchase',
        'component': AddBulkPurchase,
        'authority': [
          'company'
        ]
      },
      // {
      //   'path': '/bulkMilkPurchase/all',
      //   'name': 'All Bulk Milk Purchase',
      //   'key': 'allBulkPurchase',
      //   'component': AllBulkPurchase,
      //   'authority': [
      //     'company'
      //   ]
      // },
      {
        'path': '/bulkMilkPurchase/edit/:id',
        'name': 'Edit Bulk Purchase',
        'key': 'editBulkPurchase',
        dontShowOnMenu: true,
        'component': AddBulkPurchase,
        'authority': [
          'company'
        ]
      }
    ]
  },
  {
    'path': '/announcement',
    'name': 'Announcement',
    'icon': 'file',
    'key': 'announcement',
    dontShowOnMenu: true,
    // 'component': AddBulkPurchase,
    'authority': [
      'company',
      'manager'
    ],
    'children': [
      {
        'path': '/announcement/add',
        'name': 'Add Announcement',
        'key': 'addAnnouncemnet',
        'component': AddAnnouncement,
        'authority': [
          'company'
        ]
      },
      //  {
      //   'path': '/announcement/all',
      //   'name': 'All announcement',
      //   'key': 'allAnnouncement',
      //   'component': AllAnnouncement,
      //   'authority': [
      //     'company'
      //   ]
      // } 
    ]
  },
  // {
  //   'path': '/conflicts',
  //   'name': 'Conflicts',
  //   'icon': 'message',
  //   'key': 'conflicts',
  //   'children': [
  //     {
  //       'path': '/conflicts/all',
  //       'name': 'All Conflicts',
  //       'key': 'allConflicts',
  //       'component': AllConflicts,
  //       'authority': [
  //         'company'
  //       ]
  //     }
  //   ]
  // },
  // {
  //   'path': '/smsPurchase',
  //   'name': 'SMS Purchase',
  //   'icon': 'dollar',
  //   'key': 'smsPurchase',
  //   'component': SmsPurchase
  // },
  {
    'path': '/miscellaneous',
    'name': 'Miscellaneous',
    'icon': <DashboardOutlined/>,
    'key': 'miscellaneous',
    'children': [
      {
        'path': '/conflicts/all',
        'name': 'All Conflicts',
        'key': 'allConflicts',
        'component': AllConflicts,
        'authority': [
          'company'
        ]
      },
      {
        'path': '/announcement/all',
        'name': 'Announcement',
        'key': 'allAnnouncement',
        'component': AllAnnouncement,
        'authority': [
          'company'
        ]
      },
      {
        'path': '/bulkMilkPurchase/all',
        'name': 'Bulk Milk Purchase',
        'key': 'allBulkPurchase',
        'component': AllBulkPurchase,
        'authority': [
          'company'
        ]
      },
      {
        'path': '/allMilkReception',
        'name': 'Milk Reception',
        'key': 'allMilkReception',
        'component': AllBulkMeasurement,
        'authority': [
          'company'
        ]
      },
      {
        'path': '/milkDispatch',
        'name': 'Milk Dispatch',
        'icon': 'database',
        'key': 'milkDispatch',
        'component': MilkDispatch,
        'authority': [
          'company',
          'manager'
        ]
      },
    ]
  }
]

//<editor-fold desc="Functions Exports">
export const getUrlPushWrapper = (keyString, query) => {
  return push(getUrlPath(keyString, query))
}

export const getUrlPath = (keyString, params) => {

  if (!params) params = {}

  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')

  return `${val.path}?${queryString}`
}

export const getPushPathWrapper = (keyString, params) => {

  let obj = getUrlObject(keyString)

  if (obj) {
    const path = new Path(obj.path)

    return push(path.build(params))
  }

  return 'error'
}

export const getUrlParams = (keyString, route) => {

  let obj = getUrlObject(keyString)

  if (obj) {
    const path = new Path(obj.path)

    return path.test(route)
  }

  return { error: true }
}

export const getUrlObject = (keyString) => {

  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  return val
}
//</editor-fold>

export default menu

