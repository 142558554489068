import React, { PureComponent, useState, useEffect, useRef } from 'react'
import socketIOClient from 'socket.io-client'
import { timeStampFormat, socketUrl, dateFormat, apiUrl } from '../../../settings'
import {
  Table,
  Card,
  Tag,
  Tooltip,
  Avatar,
} from 'antd'
import { CollectionListAction, LastInsertedAction } from '../action/dashboardAction'
import { connect, useSelector, useDispatch } from 'react-redux'
import moment from 'moment';
import styles from './styles.less'
import { priceformatter } from '../../../components/_utils/utils'
import _ from 'lodash';
import Request from '../../../request';

import { connectSocket, subscribeToChat } from '../../../socket';
let unit = localStorage.getItem('measurement') || ''

function LiveCollections(props) {


  const dispatch=useDispatch();

  const [arr, setArr] = useState([])








  useEffect(() => {

    apiRequest()

    const { currentUser } = props;

    connectSocket(currentUser.companyId)

    subscribeToChat((err, data) => {
      if (err) return;    
      if(data &&  !isNaN(data.totalAmount)){
        setArr((prevState) => {
          if (prevState) {    
            let isNew=false;  
            
            _.each(prevState,(val)=>{
              if(moment(val.collectionDate).format('DD-MM-YYYY') !=moment(data.collectionDate).format('DD-MM-YYYY')){
                 isNew=true;
              }
            })
           
           
            if(isNew){              
              apiRequest();
              dispatch({ type: 'REFRESH_BOX', payload: new Date() })
              return []
             
            }else{
              dispatch({ type: 'REFRESH_BOX', payload: new Date() })
              return [data,...prevState]
            }
           

          }
        })
      }
     
    });   

  }, [])





  let apiRequest = async (params, columns) => {


    if (!params) params = {}

    const { currentUser } = props

    if (params && !params.sortField) {
      params.sortField = 'syncTime'
      params.sortOrder = 'descend'
    }

    params.page = 1
    params.results = 100
    params.count = params.results

    //params.collectionTime = collectionTime

    params.sortField = 'syncTime'
    params.sortOrder = 'descend'

    let filter = {
      ...params,
      companyId: currentUser.companyId,
    }




    let { success, data } = await Request.getDashboardLastData(filter)

    if (success) {
      setArr(data);
     

    }

  }

  let columns = [
    {
      title: 'S. No.',
      key: 's_no',
      width: '40px',
      render: (val, record, key) => {
        return <>{key + 1}</>
      }
    },
    {
      title: 'Farmer Name (Id)',
      key: 'farmerName',
      width: '100px',
      dataIndex: 'farmerName',
      render: (val, record) => {
        return <Tooltip title={record.farmerName + '(' + record.farmerId + ')'}>
          <div className={styles.ellipses}>
            {record.farmerName + '(' + record.farmerId + ')'}
          </div>
        </Tooltip>
      }
    },
    {
      title: 'Type',
      key: 'milkType',
      dataIndex: 'milkType',
      width: '40px',
      render: (val, record) => {
        return <Tooltip title={val}>
          <div className={styles.ellipses}>
            {val}
          </div>
        </Tooltip>
      }

    },
    {
      title: 'Mode',
      key: 'collectionMode',
      dataIndex: 'collectionMode',
      width: '40px',
      render: (val, record) => {
        return (val && val.toUpperCase() == 'MANUAL' ?
          <Avatar size="small" style={{ backgroundColor: '#EE9749' }}>M</Avatar> :
          <Avatar style={{ backgroundColor: '#8BC34A' }} size="small">A</Avatar>
        )
      }
    },
    {
      title: 'MCP',
      key: 'mcpId',
      width: '80px',
      dataIndex: 'mcpName',
      render: (val, record) => {
        return (
          <Tooltip title={val}>
            <div className={styles.ellipses}>
              {val}
            </div>
          </Tooltip>)
      }
    },
    {
      title: 'Status',
      key: 'status',
      width: '100px',
      dataIndex: 'status',
      render: (val, record) => {
        return (val == 'ACCEPTED' ? <div className={styles.accepted}>
          {val}
        </div> : <div className={styles.rejected}>
            {val}
          </div>)
      }
    },
    {
      title: 'FAT',
      key: 'fat',
      width: '40px',
      dataIndex: 'fat',
      render: (val) => `${val}%`
    },
    {
      title: 'SNF',
      key: 'snf',
      width: '50px',
      dataIndex: 'snf',
      render: (val) => `${val}%`
    },
    {
      title: 'Ad.W.',
      key: 'addedWater',
      width: '50px',
      dataIndex: 'addedWater',
      render: (val) => `${val || 0}%`
    },
    {
      title: 'Qty.',
      width: '50px',
      key: 'quantity',
      dataIndex: 'quantity',
      render: (val) => {
        return `${priceformatter(val)} ${unit}`
      }
    },
    // {
    //   title: 'Rate',
    //   width: '60px',
    //   key: 'rate',
    //   dataIndex: 'rate',
    //   render: (val) => `₹${val}`
    // },
    {
      title: 'Amount',
      width: '70px',
      key: 'totalAmount',
      dataIndex: 'totalAmount',
      render: (val) => `₹${priceformatter(val)}`
    },
    {
      title: 'Rate/Lt',
      key: 'ratePerLitre',
      width: '60px',
      dataIndex: 'ratePerLitre',
      render: (val) => `₹${val || 0}`
    },
    {
      title: 'Sync Time',
      key: 'syncTime',
      width: '100px',
      dataIndex: 'syncTime',
      render: date => {
        return (
          <Tooltip title={moment(date).format(timeStampFormat)}>
            {date && moment(date).fromNow()}
          </Tooltip>
        )
      }
    }
  ]
  const table = useRef(null)


  return (
    <div className={styles.liveCollection}>
      <h2>Live Milk Collection {arr.length}</h2>
      <Table
        id={'liveCollection'}
        size="small"
        columns={columns}
        ref={table}
        bordered
        dataSource={arr}
        pagination={false}
        scroll={{ y: 710 }}
      />
    </div>)

}
export default LiveCollections
