import React, { PureComponent, useState, useEffect, useRef } from 'react'
import {
  Select,
  Button,
  
  DatePicker,

  Row,
  Col,
  
} from 'antd'
import _ from 'lodash'
import DashboardAverage from './DashboardAverage'
import { connect, useSelector, useDispatch } from 'react-redux'
import styles from './styles.less'
import { timeStampFormat, socketUrl, dateFormat, apiUrl } from '../../../settings'
import WaterWave from 'ant-design-pro/lib/Charts/WaterWave'
import 'ant-design-pro/dist/ant-design-pro.css' // Import whole style
import LiveCollection from './collections';
import Summary from './Summary'
import OfflineMcp from './OfflineMcps'
const { RangePicker } = DatePicker
const { Option } = Select

// @Form.create()
function Dashboard(props) {

  const [state, setState] = useState({})
  const [summaryArr, setSummary] = useState([])
  const table = useRef(null)
  const dispatch = useDispatch()

  const { currentUser, collectionsList, collectionAveragesResp } = useSelector((state) => ({
    currentUser: state.global.currentUser,
    //collectionsList: state.dashboard.collectionsList,
   // collectionAveragesResp: state.dashboard.collectionAveragesResp

  })
  )


  return (
    <div className={styles.wrapper}>


{/* <Row>
  <Col flex="1"> <LiveCollection currentUser={currentUser} /></Col>
  <Col flex="1">
  <DashboardAverage currentUser={currentUser} />
        <Summary currentUser={currentUser} />
        <OfflineMcp currentUser={currentUser}/>
  </Col>
</Row> */}

      <div className={styles.leftContent}>

        <LiveCollection currentUser={currentUser} />
      </div>
      <div className={styles.rightContent}>
        <DashboardAverage currentUser={currentUser} />
        <Summary currentUser={currentUser} />
        <OfflineMcp currentUser={currentUser}/>
      </div>

    </div>
  )

}

export default Dashboard
