import React, { useState, useEffect, useRef } from 'react'

import styles from './login.less'
import {
    Form, Icon, Card, Input, Button, Checkbox, notification, Drawer, Modal
} from 'antd'

import { showLoader, hideLoader } from '../../../modules/actions'
import { apiUrl, SITEKEY, cognito } from '../../../settings'
import { push } from 'connected-react-router'
import { connect, useSelector, useDispatch } from 'react-redux'
import { ReCaptcha } from 'react-recaptcha-google'
import Auth from '@aws-amplify/auth';
import { loginWithPassword, verifyAuthOtp, sendAuthOtp, loginWithToken, loginWithCognito } from '../actions/loginActions'

const NormalLoginForm = (props) => {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        anchorFlag: true,
        loader: false,
        otp: undefined,
        cardLoading: true
    })
    const captchaDemoRef = useRef(null)
    let captchaDemo = captchaDemoRef.current
    const { showOtp, loading, loginResponse, sessionId, verificationLoader, otpSendLoader } = useSelector(state => ({
        showOtp: state.login.showOtp,
        loading: state.login.loginLoader,
        verificationLoader: state.login.verificationLoader,
        otpSendLoader: state.login.otpSendLoader,
        loginResponse: state.login.loginResponse,
        sessionId: state.global.sessionId
    }))
    useEffect(() => {
        dispatch({ type: 'HIDE_OTP' })
        dispatch({
            type: 'SET_LOGIN_RESPONSE',
            payload: {},
        });
        dispatch({ type: 'OTP_SEND_HIDE_LOADER' });
        dispatch({ type: 'LOGIN_HIDE_LOADER' });
        captchaDemo = captchaDemoRef.current
        if (captchaDemo) {
            captchaDemo.reset();
            captchaDemo.execute();
        }
        setState({ ...state, cardLoading: false })
    }, [captchaDemoRef])

    useEffect(() => {
        if (loginResponse && loginResponse.user) {
            setUser(loginResponse)
        }
    }, [loginResponse])

    const onFinish = () => {
        const type = process.env.REACT_APP_AUTH_TYPE
        const { recaptchaToken } = state

        console.log(type)
        switch (type) {
            case 'COGNITO':
                return (async (err, values) => {
                    if (!err) {
                        dispatch(loginWithCognito(values))
                        onLoadRecaptcha()
                    }
                })
                break;
            case 'TWO_AUTH':
                return (async (err, values) => {
                    if (!err) {
                        dispatch(loginWithPassword(values, recaptchaToken, sessionId))
                        onLoadRecaptcha()
                    }
                })
                break;
            default:
                return (async (err, values) => {
                    if (!err) {
                        dispatch(loginWithToken(values, recaptchaToken))
                        onLoadRecaptcha()
                    }
                })
                break;
        }
    }
    const handleSubmit = (e) => {
        console.log('inn')
        const { recaptchaToken } = state
        e.preventDefault()

        props.form.validateFields(onFinish())

    }

    const setUser = (data) => {
        localStorage.setItem('token', data.token)
        localStorage.setItem('measurement', 'Lt')
        localStorage.setItem('user', JSON.stringify(data.user))

        dispatch({
            type: 'SET_CURRENT_USER',
            user: data.user
        })
        setTimeout(() => {
            dispatch(push('/'))
        }, 300)
    }

    const verifyOtp = async () => {
        const { otp: otpInput } = state
        dispatch(verifyAuthOtp({ sessionId, otpInput, userId: loginResponse.userId }))
    }

    const resendOtp = async () => {
        const { mobileNumber, userId } = loginResponse
        dispatch(sendAuthOtp({ mobileNumber, userId, sessionId }))
    }

    const anchorDisable = (e) => {
        setState({
            ...state,
            anchorFlag: false
        })
    }

    const register = (e) => {
        //push to register page
        e.preventDefault()
        dispatch(push('/register'))

    }
    const forgotPassword = (e) => {
        const type = process.env.REACT_APP_AUTH_TYPE
        //push to register page
        e.preventDefault()
        if (type == 'COGNITO')
            dispatch(push('/forgotPassword'))

    }

    const onLoadRecaptcha = () => {
        if (captchaDemo) {
            captchaDemo.reset();
            captchaDemo.execute();
        }
    }
    const verifyCallback = (recaptchaToken) => {
        setState({
            ...state,
            recaptchaToken
        })
    }

    const { getFieldDecorator } = props.form
    const { flag, anchorFlag, cardLoading, otp } = state

    return (
        <Card loading={cardLoading} bordered={false} bodyStyle={{ display: 'flex' }}>
            <div className={styles.form} style={{ width: showOtp ? 0 : '100%' }}>
                <Form onSubmit={handleSubmit}>

                    <Form.Item>
                        {getFieldDecorator('email', {
                            rules: [{ required: true, message: 'Please enter your email address!' }]
                        })(
                            <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                onChange={() => { anchorDisable() }} placeholder="Email address" />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('password', {
                            rules: [{ required: true, message: 'Please input your Password!' }]
                        })(
                            <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password"
                                placeholder="Password" />
                        )}
                    </Form.Item>

                    {window.grecaptcha && <ReCaptcha
                        ref={captchaDemoRef}
                        size="invisible"
                        render="explicit"
                        sitekey={SITEKEY}
                        onloadCallback={onLoadRecaptcha}
                        verifyCallback={verifyCallback}
                    />}

                    <Button type="primary" loading={loading} htmlType="submit" className={styles.button}>
                        Log in
          </Button>
                    <div className={styles.register}>
                        {
                            process.env.REACT_APP_AUTH_TYPE=='COGNITO' && <a style={{ whiteSpace: 'nowrap' }} onClick={(e) => { forgotPassword(e) }}>Forgot Password?</a>
                        }
                        
                        <a style={{ textAlign: 'center', whiteSpace: 'nowrap' }} onClick={(e) => { register(e) }}>Register Now!</a></div>

                </Form>
            </div>
            <div className={styles.transition} style={{ display: showOtp ? 'block' : 'none' }}>
                <Form.Item label='OTP'>
                    <div style={{ display: 'flex' }}>
                        <Input value={otp} inputMode='numeric' onChange={(e) => setState({ ...state, otp: e.target.value })} maxLength={6} placeholder='OTP' />
                        <Button type='secondary' style={{ marginLeft: '10px' }} loading={otpSendLoader} onClick={resendOtp} >Resend</Button>
                    </div>
                </Form.Item>
                <Form.Item >
                    <Button type='primary' loading={verificationLoader} onClick={verifyOtp} >Verify</Button>
                    <Button style={{ marginLeft: '10px' }} type='dotted'
                        onClick={() => {
                            setState({ ...state, otp: undefined })
                            dispatch({ type: 'HIDE_OTP' })
                        }}>Use different Account</Button>
                </Form.Item>
            </div>

        </Card>
    )
}

const WrappedNormalLoginForm = Form.create()(NormalLoginForm)

export default WrappedNormalLoginForm
