import React, { PureComponent, useState, useEffect, useRef } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'

import {
    Icon,
    Tooltip,
} from 'antd'
import _ from 'lodash'



import styles from './styles.less'

import { AveragesAction } from '../action/dashboardAction'
import WaterWave from 'ant-design-pro/lib/Charts/WaterWave'
import 'ant-design-pro/dist/ant-design-pro.css' // Import whole style

function DashboardAverage(props) {
    const [state, setState] = useState({ comparisonData: [] })
    const [key, setKey] = useState(0)
    const dispatch = useDispatch()

    const { avg } = useSelector((state) => ({
        avg: state.dashboard.collectionAveragesResp.data
    })
    )

    const { refreshBox } = useSelector((state) => ({
        refreshBox: state.dashboard.refreshBox
    })
    )



    const throttled = useRef(_.throttle(() => siderData(), 10000))

    useEffect(() => throttled.current(), [refreshBox])


    const { currentUser } = props
    useEffect(() => {
        setTimeout(() => {
            setKey(1)
        }, 12000);
    }, [])



    const siderData = () => {


      //  console.log("sider aclled")
       // dispatch({ type: 'COLLECTION_AVERAGE_RESPONSE', payload: {} })
        let filter = { companyId: currentUser.companyId }
        dispatch(AveragesAction(filter))
    }

    let unit = localStorage.getItem('measurement') || ''

    return (
        <div >

            <div>
                <div style={{ textAlign: 'center', backgroundColor: 'white' }}>
                    <div className={styles.headingDummy}>{avg && avg.qty.today} {unit}</div>
                    <div style={{ margin: 10 }}>
                        {!!key && <WaterWave height={160} title="Total Milk" percent={avg && avg.qty.perc} />}
                    </div>
                </div>

                <div className={styles.compare}>

                    <div className={styles.secondCompare}>
                        <div className={styles.compareChildren} style={{ borderColor: '#BE5FC7' }}>

                            <div className={styles.compareChild} style={{ backgroundColor: '#BE5FC7' }}>
                                <div className={styles.childValue}>FAT</div>
                                <div className={styles.childSubValue}>{avg && avg.fat.today}</div>
                            </div>

                            <div className={styles.ticker}>

                                <Icon style={{
                                    color: avg && avg.fat.arrowColor
                                }}
                                    type={avg && avg.fat.arrow} />

                                <div className={styles.childData}>
                                    {avg && avg.fat.perc} %
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className={styles.secondCompare}>
                        <div className={styles.compareChildren} style={{ borderColor: '#FFBB0E' }}>
                            <div className={styles.compareChild} style={{ backgroundColor: '#FFBB0E' }}>
                                <div className={styles.childValue}> SNF</div>
                                <div className={styles.childSubValue}>{avg && avg.snf.today}  </div>

                            </div>
                            <div className={styles.ticker}>

                                <Icon style={{
                                    color: avg && avg.snf.arrowColor
                                }}
                                    type={avg && avg.snf.arrow} />

                                <div className={styles.childData}>
                                    {avg && avg.snf.perc} %
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className={styles.secondCompare}>
                        <div className={styles.compareChildren} style={{ borderColor: '#06CE4A' }}>
                            <div className={styles.compareChild} style={{ backgroundColor: '#06CE4A' }}>
                                <div className={styles.childValue}>RATE</div>
                                <div className={styles.childSubValue}>{avg && avg.rate.today} </div>

                            </div>
                            <div className={styles.ticker}>
                                <Icon style={{
                                    color: avg && avg.rate.arrowColor
                                }}
                                    type={avg && avg.rate.arrow} />
                                <div className={styles.childData}>
                                    {avg && avg.rate.perc} %
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className={styles.secondCompare}>
                        <div className={styles.compareChildren} style={{ borderColor: '#0D9CEC' }}>
                            <div className={styles.compareChild} style={{ backgroundColor: '#0D9CEC' }}>
                                <div className={styles.childValue}> QTY</div>
                                <div className={styles.childSubValue}>
                                    {avg && avg.qtyAvg.today}</div>

                            </div>
                            <div className={styles.ticker}>

                                <Icon style={{
                                    color: avg && avg.qtyAvg.arrowColor
                                }}
                                    type={avg && avg.qtyAvg.arrow} />

                                <div className={styles.childData}>
                                    {avg && avg.qtyAvg.perc} %
                                </div>

                            </div>
                        </div>

                    </div>

                </div>

            </div>



        </div>
    )
}
export default DashboardAverage